export default function paths(root: string) {
  const CLIENTS_ROOT = `${root}/clients`;
  return {
    clients: {
      root: `${CLIENTS_ROOT}`,
      list: `${CLIENTS_ROOT}`,
      add: `${CLIENTS_ROOT}/add`,
      edit: (clientId: string = ":clientId") => `${CLIENTS_ROOT}/edit/${clientId}`,
      view: (clientId: string = ":clientId") => `${CLIENTS_ROOT}/${clientId}`,
      addOrder: (clientId: string = ":clientId") => `${CLIENTS_ROOT}/${clientId}/addOrder`,
      // listOrders: (clientId: string = ":clientId") => `${clients}/${clientId}/orders`,

    },
  };
} 