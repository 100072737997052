import Button from "@mui/material/Button";

import { ConfirmDialog, LabelColor, useBoolean } from "lib-mui-template";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Tooltip from "@mui/material/Tooltip";
import {
  Iconify,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableProps,
  TableSelectedAction,
  emptyRows,
} from "lib-mui-template";

import React from "react";

export type TableSelectedActionsFasadeProps<T> = {
  table: TableProps;
  dataFiltered: T[];
  toId: (item: T) => string;
  actions: TooltipActionConfig<T>[];
};

export type TooltipActionConfig<T> = {
  icon: string;
  color: LabelColor;
  title: string;
  requiresConfirmation: boolean;
  variant: "icon" | "button";
  confirmationTitle?: string;
  confirmationContent?: React.ReactNode;
  onAction: (selectedItems: T[]) => void;
};

export default function TableSelectedActionsFasade<T>({
  toId,
  table,
  dataFiltered,
  actions,
}: TableSelectedActionsFasadeProps<T>) {
  const [confirmState, setConfirmState] = React.useState<{
    open: boolean;
    action?: TooltipActionConfig<T>;
  }>({ open: false });

  const selectedItems = table.selected
    .map((id) => dataFiltered.find((item) => toId(item) === id))
    .filter(Boolean) as T[];

  const handleActionClick = (action: TooltipActionConfig<T>) => {
    if (action.requiresConfirmation) {
      setConfirmState({ open: true, action });
    } else {
      action.onAction(selectedItems);
    }
  };

  return (
    <>
      {confirmState.open && confirmState.action && (
        <ConfirmDialog
          open={confirmState.open}
          onClose={() => setConfirmState({ open: false })}
          title={confirmState.action.confirmationTitle || ""}
          content={confirmState.action.confirmationContent || <></>}
          action={
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                confirmState.action?.onAction(selectedItems);
                setConfirmState({ open: false });
              }}
            >
              Confirm
            </Button>
          }
        />
      )}

      <TableSelectedAction
        dense={table.dense}
        numSelected={table.selected.length}
        rowCount={dataFiltered.length}
        onSelectAllRows={(checked) =>
          table.onSelectAllRows(
            checked,
            dataFiltered.map((row) => toId(row))
          )
        }
        action={
          <>
            {actions.map((action, index) => (
              <Tooltip
                key={index}
                title={action.title}
                arrow
                placement="top"
              >
                {action.variant == "button" ? (
                  <Button
                    size="small"
                    color={action.color as any}
                    startIcon={<Iconify width={24} icon={action.icon} />}
                    onClick={() => handleActionClick(action)}
                  >
                    {action.title}
                  </Button>
                ) : (
                  <IconButton
                    color={action.color}
                    onClick={() => handleActionClick(action)}
                  >
                    <Iconify width={24} icon={action.icon} />
                  </IconButton>
                )}
              </Tooltip>
            ))}
          </>
        }
      />
    </>
  );
}

export function createDeleteAction<T>(
  onAction: (selectedItems?: T[]) => Promise<void>
): TooltipActionConfig<T> {
  return {
    icon: "solar:trash-bin-trash-bold",
    color: "error",
    title: "Delete",
    variant: "icon",
    requiresConfirmation: true,
    confirmationTitle: "Delete",
    confirmationContent: (
      <>Are you sure you want to delete the selected items?</>
    ),
    onAction,
  };
}
