import { NavItemBaseProps, SvgColor } from "lib-mui-template";
import { paths } from "../../navigation/paths";
import { NavStructureProps } from "../../layouts/dashboard/types";
import { MENU_ICONS } from "src/app/components/icons";

export const dashboardMenu: NavStructureProps = [
  // OVERVIEW
  // ----------------------------------------------------------------------
  {
    subheader: "Overview",
    items: [
      {
        title: "Dashboard",
        path: paths.dashboard.root,
        icon: MENU_ICONS.dashboard,
      },
    ],
  },
  {
    subheader: "Selling & Orders",

    items: [
      {
        title: "Clients",
        path: paths.dashboard.clients.root,
        icon: MENU_ICONS.clients,
        children: [
          { title: "list", path: paths.dashboard.clients.list },
          { title: "add", path: paths.dashboard.clients.add },
        ],
      },
      {
        title: "Orders",
        path: paths.dashboard.orders.root,
        icon: MENU_ICONS.order,
        children: [
          { title: "list", path: paths.dashboard.orders.root },
          // { title: "new", path: paths.dashboard.order.new },
        ],
      },
    ],
  },

  {
    subheader: "Production & Inventory",

    items: [
      {
        title: "Products",
        path: paths.dashboard.products.root,
        icon: MENU_ICONS.products,
        children: [
          { title: "add", path: paths.dashboard.products.add },
          { title: "list", path: paths.dashboard.products.list },
        ],
      },
      {
        title: "Igredeints",
        path: paths.dashboard.ingredients.list,
        icon: MENU_ICONS.ingredients,
        // children: [
        //   { title: "list", path: paths.dashboard.ingredients.list },
        //   // { title: "add", path: paths.dashboard.tasks.add },
        // ],
      },
      {
        title: "Inventory",
        path: paths.dashboard.inventory.root,
        icon: MENU_ICONS.inventory,
      },
      {
        title: "Supplies Availability",
        path: paths.dashboard.inventory.availability,
        icon: MENU_ICONS.predictions,
      },
      {
        title: "Baking Plan",
        path: paths.dashboard.bakingPlan.root,
        icon: MENU_ICONS.bakingPlan,
      },
    ],
  },

  {
    subheader: "Delivery & Shipping",

    items: [
      {
        title: "Delivery Planning",
        path: paths.dashboard.delivery.root,
        icon: MENU_ICONS.delivery,
        // children: [
        //   { title: "list", path: paths.dashboard.tasks.list },
        //   // { title: "add", path: paths.dashboard.tasks.add },
        // ],
      },
    ],
  },

  {
    subheader: "Marketing",

    items: [
      {
        title: "Tasks",
        path: paths.dashboard.tasks.list,
        icon: MENU_ICONS.tasks,
        // children: [
        //   { title: "list", path: paths.dashboard.tasks.list },
        //   // { title: "add", path: paths.dashboard.tasks.add },
        // ],
      },
    ],
  },

  // {
  //   subheader: "AAA managements",
  //   items: [
  //     {
  //       title: "users",
  //       path: "paths.dashboard.group.root",
  //       icon: MENU_ICONS.user,
  //       children: [
  //         { title: "four", path: "paths.dashboard.group.root" },
  //         { title: "five", path: "paths.dashboard.group.five" },
  //         { title: "six", path: "paths.dashboard.group.six" },
  //       ],
  //     },
  //   ],
  // },
];
