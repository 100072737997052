import { Suspense, lazy } from "react";
import { Outlet } from "react-router-dom";

// import Error404Page from "../pages/Error404Page";
// import { AuthGuard } from "../auth/guard";
import { LoadingScreen } from "lib-mui-template";
import { AuthGuard } from "src/app/auth/guard";
import DashboardLayout from "src/app/layouts/dashboard";
import { NavConfig } from "src/app/layouts/dashboard/types";
import { dashboardMenu } from "./menu";
import { default as dashbaordPaths } from "src/app/pages/dashboard/paths";
import BakingPlansPage from "./baking-plan/list/BakingPlansPage";

const ClientsAddPage = lazy(() => import( "src/app/pages/dashboard/clients/add/ClientsAddPage"));
const ClientsAddOrderPage = lazy(() => import( "src/app/pages/dashboard/clients/addOrder/ClientsAddOrderPage"));
const ClientsEditPage = lazy(() => import( "src/app/pages/dashboard/clients/edit/ClientsEditPage"));
const ClientsListPage = lazy(() => import( "src/app/pages/dashboard/clients/list/ClientsListPage"));
const ClientsViewPage = lazy(() => import( "src/app/pages/dashboard/clients/view/ClientsViewPage"));
const OrdersEditPage = lazy(() => import( "src/app/pages/dashboard/orders/edit/OrdersEditPage"));
const OrdersListPage = lazy(() => import( "src/app/pages/dashboard/orders/list/OrdersListPage"));
const ProductsAddPage = lazy(() => import( "src/app/pages/dashboard/products/add/ProductsAddPage"));
const ProductsEditPage = lazy(() => import( "src/app/pages/dashboard/products/edit/ProductsEditPage"));
const ProductsListPage = lazy(() => import( "src/app/pages/dashboard/products/list/ProductsListPage"));
const SetupPage = lazy(() => import( "src/app/pages/dashboard/user/SetupPage"));
const DashboardOverviewPage = lazy(() => import( "src/app/pages/dashboard/overview/DashboardOverviewPage"));
const TasksListPage = lazy(() => import( "src/app/pages/dashboard/tasks/list/TasksListPage"));
const FeedbacksAddPage = lazy(() => import( "src/app/pages/dashboard/feedbacks/add/FeedbackAddPage"));
const FeedbacksEditPage = lazy(() => import( "src/app/pages/dashboard/feedbacks/edit/FeedbacksEditPage"));
const IngredientsListPage = lazy(() => import( "src/app/pages/dashboard/ingredients/list/IngredientsListPage"));
const InventoryPage = lazy(() => import( "src/app/pages/dashboard/inventory/update/InventoryPage"));
const InventoryAvailabilityPage = lazy(() => import( "src/app/pages/dashboard/inventory/availability/InventoryAvailabilityPage"));
const DeliveryPage = lazy(() => import( "src/app/pages/dashboard/delivery/DeliveryPage"));
const UserProfilePage = lazy(() => import("src/app/pages/dashboard/user/UserProfilePage"));
const BakingPlanEditPage = lazy(() => import("src/app/pages/dashboard/baking-plan/edit/BakingPlanEditPage"));


const paths = dashbaordPaths();
const dashboardNavConfig: NavConfig = {
  navContent: {},
  navStructure: dashboardMenu,
};

export const dashboardRoutes = [
  {
    path: paths.dashboard.root,
    element: (
      <AuthGuard>
        <DashboardLayout navConfig={dashboardNavConfig}>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { index: true, element: <DashboardOverviewPage /> },
      { path: paths.dashboard.user.profile, element: <UserProfilePage /> },
      { path: paths.dashboard.user.setup, element: <SetupPage /> },
      {
        path: paths.dashboard.clients.add,
        element: <ClientsAddPage />,
      },
      {
        path: paths.dashboard.clients.edit(),
        element: <ClientsEditPage />,
      },
      {
        path: paths.dashboard.clients.view(),
        element: <ClientsViewPage />,
      },
      {
        path: paths.dashboard.clients.list,
        element: <ClientsListPage />,
      },
      {
        path: paths.dashboard.clients.addOrder(),
        element: <ClientsAddOrderPage />,
      },
      {
        path: paths.dashboard.orders.root,
        element: <OrdersListPage />,
      },
      {
        path: paths.dashboard.orders.edit(),
        element: <OrdersEditPage />,
      },
      {
        path: paths.dashboard.products.add,
        element: <ProductsAddPage />,
      },
      {
        path: paths.dashboard.products.list,
        element: <ProductsListPage />,
      },
      {
        path: paths.dashboard.products.edit(),
        element: <ProductsEditPage />,
      },
      {
        path: paths.dashboard.tasks.list,
        element: <TasksListPage />,
      },
      {
        path: paths.dashboard.feedbacks.addToClientOrder(),
        element: <FeedbacksAddPage />,
      },
      {
        path: paths.dashboard.feedbacks.addToClient(),
        element: <FeedbacksAddPage />,
      },
      {
        path: paths.dashboard.feedbacks.edit(),
        element: <FeedbacksEditPage />,
      },
      {
        path: paths.dashboard.ingredients.list,
        element: <IngredientsListPage />,
      },
      {
        path: paths.dashboard.inventory.root,
        element: <InventoryPage />,
      },
      {
        path: paths.dashboard.inventory.availability,
        element: <InventoryAvailabilityPage />,
      },
      {
        path: paths.dashboard.delivery.root,
        element: <DeliveryPage />,
      },
      {
        path: paths.dashboard.bakingPlan.root,
        element: <BakingPlansPage />,
      },
      {
        path: paths.dashboard.bakingPlan.edit(),
        element: <BakingPlanEditPage />,
      },
    ],
  },
];

function Page({ text }: { text: string }) {
  return <div>{text}</div>;
}
