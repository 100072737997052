import Box, { BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type ErrorOptions = { error: Error } | { title: string; message: string };

import Button from "@mui/material/Button";

import { Iconify, RouterLink } from "lib-mui-template";
import { paths } from "src/app/navigation/paths";


type ErrorScreenProps = BoxProps & ErrorOptions;

export default function ErrorScreen({ sx, ...other }: ErrorScreenProps) {
  let title = "";
  let message = "";
 
  if ("error" in other) {
    title = other.error.name;
    message = other.error.message;
  } else {
    title = other.title;
    message = other.message;
  }
  return (
    <Box
      sx={{
        px: 5,
        width: 1,
        flexGrow: 1,
        minHeight: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
      {...other}
    >
      <Box>
        <Iconify
          icon="bi:exclamation-triangle"
          color="error"
          sx={{ width: 80, height: 80 }}
        />
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1">{message}</Typography>
        <Box sx={{ mt: 5 }}>
          <Button
            component={RouterLink}
            href= {paths.dashboard.root}
            size="large"
            variant="contained"
          >
            Go to Dashboard
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

type ErrorScreenNoParamDefinedProps = BoxProps & { paramName: string };

export const ErrorScreenNoParamDefined = ({
  paramName,
  ...others
}: ErrorScreenNoParamDefinedProps) => {
  return (
    <ErrorScreen
      title="Error"
      message={`Lack of Error ${paramName}, check if link is correct`}
    />
  );
};
