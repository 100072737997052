export default function paths(root: string) {
  const ROOT = `${root}/feedbacks`;
  return {
    feedbacks: {
      root: `${ROOT}`,
      list: `${ROOT}`,
      addToClient: (clientId: string = ":clientId") => `${ROOT}/addToClient/${clientId}`,
      addToClientOrder: (clientId: string = ":clientId", orderId: string = ":orderId") => `${ROOT}/addToClientOrder/${clientId}/${orderId}`,
      edit: (feedbackId: string = ":feedbackId") => `${ROOT}/edit/${feedbackId}`,
      view: (feedbackId: string = ":feedbackId") => `${ROOT}/edit/${feedbackId}`,

    },
  };
} 