import { createContext, useContext } from "react";
import { AppRegistry } from "./app-main";
import { BusinessLogic } from "./app-business-logic";

// Create the context
const AppContext = createContext<AppRegistry | null>(null);

export function AppRegistryProvider({appRegistry, children }: { appRegistry:AppRegistry, children: React.ReactNode }) {
    return (
        <AppContext.Provider value={appRegistry}>
            {children}
        </AppContext.Provider>
    );
}

// Custom hook to access the context value
export const useAppRegistry = (): AppRegistry => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error("useAppContext must be used within an AppContextProvider");
    }
    return context;
};

export const useAppBusinessLogic = (): BusinessLogic => {
    const { businessLogic } = useAppRegistry();
    return businessLogic;
};
