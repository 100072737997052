export default function paths(root: string) {
  const ROOT = `${root}/delivery`;
  return {
    delivery: {
      root: `${ROOT}`,
      delivery: `${ROOT}`,
      // availability: `${ROOT}/availability`,
      // addToClient: (ingredientId: string = ":ingredientId") => `${ROOT}/addToClient/${ingredientId}`,
      // addToClientOrder: (ingredientId: string = ":ingredientId", orderId: string = ":orderId") => `${ROOT}/addToClientOrder/${ingredientId}/${orderId}`,
      // edit: (ingredientId: string = ":ingredientId") => `${ROOT}/edit/${ingredientId}`,
      // view: (ingredientId: string = ":ingredientId") => `${ROOT}/edit/${ingredientId}`,

    },
  };
} 