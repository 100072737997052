export default function paths(root: string) {
  const PRODUCTS_ROOT = `${root}/products`;
  return {
    products: {
      root: `${PRODUCTS_ROOT}`,
      list: `${PRODUCTS_ROOT}`,
      add: `${PRODUCTS_ROOT}/add`,
      edit: (productId: string = ":productId") => `${PRODUCTS_ROOT}/edit/${productId}`,
      view: (productId: string = ":productId") => `${PRODUCTS_ROOT}/edit/${productId}`,

    },
  };
} 