import { Iconify, SvgColor } from "lib-mui-template";

// ----------------------------------------------------------------------
const assetIcon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);
const fontIcon = (name: string, width?:number) => (
  <Iconify icon={name} width={width} />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

export const DATA_GRID_ICONS = {
  edit: fontIcon("solar:pen-new-square-bold-duotone",),
  delete: fontIcon("solar:trash-bin-trash-bold-duotone"),
  add: fontIcon("solar:add-square-bold-duotone",24),
  // save: fontIcon("solar:file-check-bold-duotone"),
  save: fontIcon("solar:check-square-bold-duotone",22),
  // save: fontIcon("solar:diskette-bold-duotone"),
  cancel: fontIcon("solar:close-square-bold-duotone",22),
  plus: fontIcon("solar:add-circle-bold-duotone",22),
  minus: fontIcon("solar:minus-circle-bold-duotone",22),
  expand: fontIcon("solar:alt-arrow-down-bold-duotone",22),
  colapse: fontIcon("solar:alt-arrow-up-bold-duotone",22),
};

// https://icon-sets.iconify.design/solar/ --food and kitchen category
export const MENU_ICONS = {
  job: assetIcon("ic_job"),
  blog: assetIcon("ic_blog"),
  chat: assetIcon("ic_chat"),
  mail: assetIcon("ic_mail"),
  user: assetIcon("ic_user"),
  clients: assetIcon("ic_clients"),
  // clients: fontIcon("solar:users-group-two-rounded-bold-duotone"),
  file: assetIcon("ic_file"),
  lock: assetIcon("ic_lock"),
  tour: assetIcon("ic_tour"),
  order: assetIcon("ic_order"),
  label: assetIcon("ic_label"),
  blank: assetIcon("ic_blank"),
  kanban: assetIcon("ic_kanban"),
  folder: assetIcon("ic_folder"),
  banking: assetIcon("ic_banking"),
  booking: assetIcon("ic_booking"),
  invoice: assetIcon("ic_invoice"),
  // product: assetIcon("ic_product"),
  // product: fontIcon("icon-park-twotone:bread"),
  // tasks: fontIcon("solar:checklist-minimalistic-bold-duotone"),
  // tasks: fontIcon("solar:archive-minimalistic-bold-duotone"),
  bakingPlan: fontIcon("solar:chef-hat-heart-bold-duotone",24),
  predictions: fontIcon("material-symbols:batch-prediction-rounded",24),
  updateInventory: fontIcon("solar:smartphone-update-bold-duotone",24),
  productDisplay: fontIcon("solar:display-bold-duotone"),
  recipe: fontIcon("solar:document-add-bold-duotone"),
  cooking: fontIcon("solar:oven-mitts-minimalistic-bold-duotone"),
  tasks: fontIcon("solar:clipboard-check-bold-duotone"),
  product: fontIcon("solar:donut-bitten-bold-duotone"),
  inventory: fontIcon("solar:fridge-bold-duotone"),
  products: fontIcon("solar:donut-bold-duotone"),
  breadMachine: fontIcon("icon-park-twotone:bread-machine"),
  ingredients: fontIcon("solar:clipboard-list-bold-duotone"),
  routing: fontIcon("solar:routing-2-bold-duotone"),
  delivery: fontIcon("solar:delivery-bold-duotone"),
  // ingredients: fontIcon("solar:document-text-bold-duotone"),
  calendar: assetIcon("ic_calendar"),
  disabled: assetIcon("ic_disabled"),
  external: assetIcon("ic_external"),
  menuItem: assetIcon("ic_menu_item"),
  ecommerce: assetIcon("ic_ecommerce"),
  analytics: assetIcon("ic_analytics"),
  dashboard: assetIcon("ic_dashboard"),
};

