export default function paths(root: string) {
  const TASKS_ROOT = `${root}/tasks`;
  return {
    tasks: {
      root: `${TASKS_ROOT}`,
      list: `${TASKS_ROOT}`,
      add: `${TASKS_ROOT}/add`,
      edit: (taskId: string = ":taskId") => `${TASKS_ROOT}/edit/${taskId}`,
      view: (taskId: string = ":taskId") => `${TASKS_ROOT}/edit/${taskId}`,

    },
  };
} 