import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Tooltip from "@mui/material/Tooltip";
import { Dayjs } from "dayjs";
import {
  ConfirmDialog,
  Iconify,
  Scrollbar,
  TableEmptyRows,
  TableHeadCustom,
  TableLoading,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  emptyRows,
  getComparator,
  useBoolean,
  useRouter,
  useSnackbar,
  useTable,
} from "lib-mui-template";
import { UseTableProps } from "lib-mui-template/dist/components/table/use-table";
import { Order } from "lib-shared-code";
import { useCallback, useState } from "react";

export type applyFilterFunc<T, Filter> = ({
  inputData,
  comparator,
  filters,
}: {
  inputData: T[];
  comparator: (
    a: {
      [x: string]: any; // string | number | Dayjs;
    },
    b: {
      [x: string]: any; // string | number | Dayjs;
    }
  ) => number;
  filters: Filter;
}) => T[];

export type useTableWithBasicHandlersProps<T, Filter, FilterValue> = {
  items: T[];
  doDeleteItem: (id: string) => Promise<void>;
  toViewPath: (id: string) => string;
  toId: (item: T) => string;
  tableDefaults: UseTableProps;
  defaultFilters: Filter;
  applyFilter: applyFilterFunc<T, Filter>;
};

export default function useTableWithBasicHandlers<T, Filter, FilterValue>({
  toViewPath,
  toId,
  tableDefaults,
  items,
  doDeleteItem,
  defaultFilters,
  applyFilter,
}: useTableWithBasicHandlersProps<T, Filter, FilterValue>) {
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const [tableData, setTableData] = useState<T[]>(items);
  const table = useTable(tableDefaults);

  const [filters, setFilters] = useState(defaultFilters);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const handleViewRow = useCallback(
    (id: string) => {
      router.push(toViewPath(id));
    },
    [router]
  );

  const handleDeleteRow = useCallback(
    async (id: string) => {
      await doDeleteItem(id);
      const deleteRow = tableData.filter((row) => toId(row) !== id);

      enqueueSnackbar("Delete success!");

      setTableData(deleteRow);

      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [dataInPage.length, enqueueSnackbar, table, tableData]
  );

  const handleDeleteRows = useCallback(async () => {
    for (let i = 0; i < table.selected.length; i++) {
      await doDeleteItem(table.selected[i]);
    }

    const deleteRows = tableData.filter(
      (row) => !table.selected.includes(toId(row))
    );

    enqueueSnackbar("Delete success!");

    setTableData(deleteRows);

    table.onUpdatePageDeleteRows({
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    });
  }, [
    dataFiltered.length,
    dataInPage.length,
    enqueueSnackbar,
    table,
    tableData,
  ]);

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleFilters = useCallback(
    (name: string, value: FilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters("status", newValue as FilterValue);
    },
    [handleFilters]
  );

  return {
    table,
    tableData,
    setTableData,
    dataInPage,
    filters,
    setFilters,
    handleResetFilters,
    dataFiltered,
    handleViewRow,
    handleDeleteRow,
    handleDeleteRows,
    handleFilters,
    handleFilterStatus,
  };
}
