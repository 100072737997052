import Card from "@mui/material/Card";
import TableContainer from "@mui/material/TableContainer";
import {
  HeadLabelProps,
  Scrollbar,
  useRouter,
  useSnackbar,
} from "lib-mui-template";
import useTableWithBasicHandlers from "src/app/components/list-table-fasade/useTableWithBasicHandlers";

import { useMemo } from "react";

import TableSelectedActionsFasade, { TooltipActionConfig, createDeleteAction } from "src/app/components/list-table-fasade/TableSelectedActionsFasade";

import {
  TableFasade,
  TablePaginationCustomFasade,
} from "src/app/components/list-table-fasade/table-components";
import { paths } from "src/app/navigation/paths";
import { BakingPlan } from "lib-shared-code";
import BakingPlansListTableRow from "./BakingPlansListTableRow";

export const TABLE_HEAD: HeadLabelProps[] = [
  { id: "name", label: "Plan Name" },
  { id: "mixingPlan", label: "Mixing Plan Details", align: "center" },
  { id: "orderIds", label: "Associated Orders", width: 200 },
];

export type BakingPlansListViewProps = {
  data: BakingPlan[];
  onDeleteItem: (id: string) => Promise<void>;
};

export type BakingPlanFilter = {
  name: string;
};

export type BakingPlanFilterValue = string | null;

export default function BakingPlansListView({
  data,
  onDeleteItem,
}: BakingPlansListViewProps) {
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();

  const defaultFilters: BakingPlanFilter = {
    name: "",
  };

  const { table, dataFiltered, filters, handleDeleteRow, handleDeleteRows } =
    useTableWithBasicHandlers<
      BakingPlan,
      BakingPlanFilter,
      BakingPlanFilterValue
    >({
      items: data,
      doDeleteItem: onDeleteItem,
      toViewPath: (id) => paths.dashboard.bakingPlan.edit(id),
      toId: (item) => item.id,
      tableDefaults: {
        defaultRowsPerPage: 10,
        defaultOrder: "asc",
        defaultOrderBy: "name",
      },
      defaultFilters: defaultFilters,
      applyFilter: ({ inputData, filters }) => inputData,
    });

  const notFound = dataFiltered.length === 0;

  const denseHeight = table.dense ? 56 : 56 + 20;

  const deleteTooltipAction: TooltipActionConfig<BakingPlan> = useMemo(
    () => createDeleteAction<BakingPlan>(handleDeleteRows),
    [handleDeleteRows]
  );

  return (
    <>
      <Card>
        <TableContainer sx={{ position: "relative", overflow: "unset" }}>
          <TableSelectedActionsFasade<BakingPlan>
            dataFiltered={dataFiltered}
            table={table}
            toId={(row) => row.id}
            actions={[deleteTooltipAction]}
          />

          <Scrollbar>
            <TableFasade<BakingPlan>
              dataFiltered={dataFiltered}
              table={table}
              notFound={notFound}
              tableHeaders={TABLE_HEAD}
              toId={(row) => row.id}
              denseHeight={denseHeight}
              rowMapper={(row) => (
                <BakingPlansListTableRow
                  key={row.id}
                  row={row}
                  selected={table.selected.includes(row.id)}
                  onSelectRow={() => table.onSelectRow(row.id)}
                  onDeleteRow={() => handleDeleteRow(row.id)}
                  onViewRow={() =>
                    router.push( paths.dashboard.bakingPlan.edit(row.id) )
                  }
                />
              )}
            />
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustomFasade<BakingPlan>
          table={table}
          dataFiltered={dataFiltered}
        />
      </Card>
    </>
  );
}
