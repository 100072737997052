import Box from '@mui/material/Box';

import { useBoolean } from 'lib-mui-template';
import { useResponsive } from 'lib-mui-template';

import { useSettingsContext } from 'lib-mui-template';

import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';
import { NavConfig } from '../dashboard/types';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  navConfig: NavConfig;
};

export default function DashboardLayout({ children, navConfig }: Props) {
  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini navConfig={navConfig} />;

  const renderHorizontal = <NavHorizontal  navConfig={navConfig}  />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse}  navConfig={navConfig} />;

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>{children}</Main>
      </Box>
    </>
  );
}
