import { authPaths } from "./sections/auth-paths";
import { dashbaordPaths } from "../pages/dashboard/paths";

// const ROOTS = {
//   // AUTH: "/auth",
//   // DASHBOARD: "/dashboard",

// };

export const paths = {
  pathAfterLogin: "/",
  placeHolder: "/placeholder",

  //auth.firebase.login
  page403: "/403",
  page404: "/404",
  page500: "/500",
  // AUTH
  ...dashbaordPaths,
  ...authPaths,
};

export function getSubPath(path: string, rootPath: string) {
  return path.replace(rootPath+"/", "");
}
