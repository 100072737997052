import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Tooltip from "@mui/material/Tooltip";
import {
  Iconify,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableProps,
  TableSelectedAction,
  emptyRows,
  HeadLabelProps,
} from "lib-mui-template";

export type TablePaginationCustomFasadeProps<T> = {
  table: TableProps;
  dataFiltered: T[];
};
export function TablePaginationCustomFasade<T>({
  table,
  dataFiltered,
}: TablePaginationCustomFasadeProps<T>) {
  return (
    <TablePaginationCustom
      count={dataFiltered.length}
      page={table.page}
      rowsPerPage={table.rowsPerPage}
      onPageChange={table.onChangePage}
      onRowsPerPageChange={table.onChangeRowsPerPage}
      dense={table.dense}
      onChangeDense={table.onChangeDense}
    />
  );
}

export type TableFasadeProps<T> = {
  table: TableProps;
  dataFiltered: T[];
  toId: (item: T) => string;
  rowMapper: (row: T) => JSX.Element;
  denseHeight: number;
  notFound: boolean;
  tableHeaders: HeadLabelProps[];
};

export function TableFasade<T>({
  table,
  dataFiltered,
  rowMapper,
  denseHeight,
  notFound,
  toId,
  tableHeaders,
}: TableFasadeProps<T>) {
  return (
    <Table size={table.dense ? "small" : "medium"} sx={{ minWidth: 960 }}>
      <TableHeadCustom
        order={table.order}
        orderBy={table.orderBy}
        headLabel={tableHeaders}
        rowCount={dataFiltered.length}
        numSelected={table.selected.length}
        onSort={table.onSort}
        onSelectAllRows={(checked) =>
          table.onSelectAllRows(
            checked,
            dataFiltered.map((row) => toId(row))
          )
        }
      />

      <TableBody>
        {dataFiltered
          .slice(
            table.page * table.rowsPerPage,
            table.page * table.rowsPerPage + table.rowsPerPage
          )
          .map(rowMapper)}

        <TableEmptyRows
          height={denseHeight}
          emptyRows={emptyRows(
            table.page,
            table.rowsPerPage,
            dataFiltered.length
          )}
        />

        <TableNoData notFound={notFound} />
      </TableBody>
    </Table>
  );
}

export type TableSelectedActionFasadeProps<T> = {
  table: TableProps;
  dataFiltered: T[];
  toId: (item: T) => string;
  openConfirm: () => void;
};

/**
 * @deprecated use TableSelectedActionsFasade instead
 * @param param 
 * @returns 
 */
export function TableSelectedActionFasade<T>({
  toId,
  table,
  dataFiltered,
  openConfirm,
}: TableSelectedActionFasadeProps<T>) {
  return (
    <TableSelectedAction
      dense={table.dense}
      numSelected={table.selected.length}
      rowCount={dataFiltered.length}
      onSelectAllRows={(checked) =>
        table.onSelectAllRows(
          checked,
          dataFiltered.map((row) => toId(row))
        )
      }
      action={
        <Tooltip title="Delete">
          <IconButton color="primary" onClick={openConfirm}>
            <Iconify icon="solar:trash-bin-trash-bold" />
          </IconButton>
        </Tooltip>
      }
    />
  );
}
