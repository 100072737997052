import { LoadingScreen } from "lib-mui-template";
import useDataFetchAll, { FetchAll } from "src/app/data/use-data-fetch-all";
import ErrorScreen from "./ErrorScreen";
import { DataLoadingStatus } from "./data-loading-status";

type Props<T> = {
  dataFetcher: FetchAll<T>;
};


// Define a type for the return value of the hook that includes the state
type DataListLoadingResult<T> =
  | { status: DataLoadingStatus.Loading; loadingOrErrorMessage: JSX.Element; data?: never }
  | { status: DataLoadingStatus.Loaded; loadingOrErrorMessage?: never; data: T[] }
  | {
      status: DataLoadingStatus.NotFound | DataLoadingStatus.Error;
      loadingOrErrorMessage: JSX.Element;
      data?: never;
    };

export default function useDataListLoading<T>({
  dataFetcher,
}: Props<T>): DataListLoadingResult<T> {
  const { data, isLoading, error } = useDataFetchAll<T>(dataFetcher);

  if (isLoading) {
    const content = <LoadingScreen />;
    return { status: DataLoadingStatus.Loading, loadingOrErrorMessage: content };
  } else if (error) {
    const content = <ErrorScreen title="Error" message={error} />;
    return { status: DataLoadingStatus.Error, loadingOrErrorMessage: content };
  } else if (data == null) {
    const content = <ErrorScreen title="Error" message="Data not found" />;
    return { status: DataLoadingStatus.NotFound, loadingOrErrorMessage: content };
  } else {
    return { status: DataLoadingStatus.Loaded, data };
  }
}
