import { HelmetProps } from "react-helmet-async";
import React, { ReactElement, ReactNode, useCallback } from "react";
import AppDecorator from "src/app/app-decorator";
import { useAppRegistry } from "src/app/app-context";

export interface LibHelmetProps extends HelmetProps {}

export interface LibHelmet extends AppDecorator {
  render: (props: LibHelmetProps) => ReactElement<LibHelmetProps>;
}

export function useHelmet(props: LibHelmetProps): () => ReactElement | null {
  const { helmet } = useAppRegistry();

  // Return a functional component that renders the helmet content
  const HelmetComponent = useCallback(() => {
    // If the helmet instance is not available, render nothing
    if (!helmet) return null;
    // Otherwise, use the render method of the helmet instance
    return helmet.render(props);
  }, [helmet, props]);

  return HelmetComponent;
}