
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Iconify } from "lib-mui-template";
import { useRouter } from "next/router"; // Assuming useRouter for routing
import { BakingPlan } from "lib-shared-code";

type Props = {
  row: BakingPlan;
  selected: boolean;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  onViewRow: VoidFunction;
};

export default function BakingPlansListTableRow({
  row,
  selected,
  onSelectRow,
  onDeleteRow,
  onViewRow,
}: Props) {
  const { id, name, mixingPlan } = row;

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onChange={onSelectRow} />
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography onClick={onViewRow} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
            {name}
          </Typography>
        </TableCell>
        <TableCell align="right">{mixingPlan.inputOrderItems.length} ordered items</TableCell>
        <TableCell align="right">
          <IconButton onClick={onViewRow} color="primary">
            <Iconify icon="akar-icons:eye" />
          </IconButton>
          <IconButton onClick={onDeleteRow} color="error">
            <Iconify icon="fluent:delete-24-filled" />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}
