import { memo } from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';

import { useMockedUser } from 'lib-mui-template';
import { useAuthContext } from 'src/app/auth/hooks';

import { bgBlur } from 'lib-mui-template';

import {Scrollbar} from 'lib-mui-template';
import { NavSectionHorizontal } from 'lib-mui-template';

import { HEADER } from '../config-layout';
import HeaderShadow from '../common/header-shadow';
import { NavConfig } from '../dashboard/types';

// ----------------------------------------------------------------------

type Props = {
  navConfig: NavConfig;
};

function NavHorizontal({navConfig}:Props) {
  const theme = useTheme();

  const { user } = useAuthContext();

  // const navData = useNavData();

  return (
    <AppBar
      component="div"
      sx={{
        top: HEADER.H_DESKTOP_OFFSET,
      }}
    >
      <Toolbar
        sx={{
          ...bgBlur({
            color: theme.palette.background.default,
          }),
        }}
      >
        <Scrollbar
          sx={{
            '& .simplebar-content': {
              display: 'flex',
            },
          }}
        >
          <NavSectionHorizontal
            data={navConfig.navStructure}
            slotProps={{
              currentRole: user?.role,
            }}
            sx={{
              ...theme.mixins.toolbar,
            }}
          />
        </Scrollbar>
      </Toolbar>

      <HeaderShadow />
    </AppBar>
  );
}

export default memo(NavHorizontal);
