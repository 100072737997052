import { useState, useEffect } from 'react';

export type FetchAll<T> = () => Promise<T[]>;

function useDataFetchAll<T>(fetcher: FetchAll<T>) {
  const [data, setData] = useState<T[]|null>(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetcher();
        setData(result);
      } catch (err) {
        setError(err instanceof Error ? err.message : String(err));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, isLoading, error };
}

export default useDataFetchAll;
