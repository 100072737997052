import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { CustomBreadcrumbs, Iconify, RouterLink, useSettingsContext } from "lib-mui-template";
import { useAppBusinessLogic } from "src/app/app-context";
import { paths } from "src/app/navigation/paths";

import { useHelmet } from "src/app/lib/helmet/lib-helmet";
import useDataListLoading from "src/app/components/processing/useDataListLoading";
import { DataLoadingStatus } from "src/app/components/processing/data-loading-status";
import BakingPlansListView from "./BakingPlansListView";

export default function BakingPlansPage() {
  const settings = useSettingsContext();
  const title = "List of Baking Plans"; // Updated title to reflect baking plans

  const Helmet = useHelmet({ title });
  const { bakingPlanBl } = useAppBusinessLogic(); // Assuming you have business logic for baking plans
  const { loadingOrErrorMessage, data, status } = useDataListLoading({
    dataFetcher: bakingPlanBl.getBakingPlans, // Method to fetch baking plans
  });

  if (status !== DataLoadingStatus.Loaded) return loadingOrErrorMessage;

  return (
    <>
      <Helmet />
      <Container maxWidth={settings.themeStretch ? false : "lg"}>
        <CustomBreadcrumbs
          heading={title}
          links={[
            { name: "Dashboard", href: paths.dashboard.root },
            { name: title },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <BakingPlansListView data={data ?? []} onDeleteItem={bakingPlanBl.deleteBakingPlan} />
      </Container>
    </>
  );
}

