export default function paths(root: string) {
  const ORDERS_ROOT = `${root}/orders`;
  return {
    orders: {
      root: `${ORDERS_ROOT}`,
      list: `${ORDERS_ROOT}`,
      // add: `${ORDERS_ROOT}/add`,
      edit: (orderId: string = ":orderId") => `${ORDERS_ROOT}/edit/${orderId}`,
      view: (orderId: string = ":orderId") => `${ORDERS_ROOT}/edit/${orderId}`,

    },
  };
} 