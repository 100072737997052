import { default as clientPaths } from "./clients/paths";
import { default as orderPaths } from "./orders/paths";
import { default as productPaths } from "./products/paths";
import { default as taskPaths } from "./tasks/paths";
import { default as feedbackPaths } from "./feedbacks/paths";
import { default as ingredientPaths } from "./ingredients/paths";
import { default as inventoryPaths } from "./inventory/paths";
import { default as deliveryPaths } from "./delivery/paths";
import { default as bakinPlanPaths } from "./baking-plan/paths";

export default function paths(root: string = "") {
  const name = "dashboard";
  const rootPath = `${root}/${name}`;
  return {
    [name]: {
      root: "/"+name,
      ...clientPaths(rootPath),
      ...orderPaths(rootPath),
      ...productPaths(rootPath),
      ...taskPaths(rootPath),
      ...feedbackPaths(rootPath),
      ...ingredientPaths(rootPath),
      ...inventoryPaths(rootPath),
      ...deliveryPaths(rootPath),
      ...bakinPlanPaths(rootPath),
      user: {
        root: `${rootPath}/user/profile`,
        profile: `${rootPath}/user/profile`,
        setup: `${rootPath}/user/setup`,
      },
    },
  };
}

export const dashbaordPaths = paths();

