export default function paths(root: string) {
  const ROOT = `${root}/baking-plan`;
  return {
    bakingPlan: {
      root: `${ROOT}`,
      // baking-plan: `${ROOT}`,
      // availability: `${ROOT}/availability`,
      // addToClient: (bakingPlanId: string = ":bakingPlanId") => `${ROOT}/addToClient/${bakingPlanId}`,
      // addToClientOrder: (bakingPlanId: string = ":bakingPlanId", orderId: string = ":orderId") => `${ROOT}/addToClientOrder/${bakingPlanId}/${orderId}`,
      edit: (bakingPlanId: string = ":bakingPlanId") => `${ROOT}/edit/${bakingPlanId}`,
      // view: (bakingPlanId: string = ":bakingPlanId") => `${ROOT}/edit/${bakingPlanId}`,

    },
  };
} 